import { useState, useEffect } from 'react';
import {cancelOrderApi, getOrderCoordinates, getOrderCoordinatesApi, getUserOrders} from '../utils/api/ordersApi'; // Импортируем функцию API

export const useUserOrders = () => {
    const [orders, setOrders] = useState(null); // Состояние для хранения данных заказов
    const [loading, setLoading] = useState(false); // Состояние для индикации загрузки
    const [error, setError] = useState(null); // Состояние для хранения ошибок

    // Функция для получения заказов
    const fetchUserOrders = async () => {
        setLoading(true); // Включаем индикацию загрузки
        setError(null); // Сбрасываем ошибки

        try {
            const data = await getUserOrders(); // Вызов API для получения заказов
            setOrders(data); // Устанавливаем данные заказов
        } catch (err) {
            setError(err); // Устанавливаем ошибку, если запрос не удался
        } finally {
            setLoading(false); // Выключаем индикацию загрузки
        }
    };


    const [cancelOrder, setCancelOrder] = useState(null);
    const [cancelOrderLoading, setCancelOrderLoading] = useState(false);
    const [cancelOrderError, setCancelOrderError] = useState(null);

    // Функция для получения заказов
    const fetchCancelOrder = async (number, fromReason, desc) => {
        setCancelOrderLoading(true); // Включаем индикацию загрузки
        setCancelOrderError(null); // Сбрасываем ошибки

        try {
            const data = await cancelOrderApi({number:number, formReason:fromReason, desc:desc}); // Вызов API для получения заказов
            setCancelOrder(data); // Устанавливаем данные заказов
        } catch (err) {
            setCancelOrderError(err); // Устанавливаем ошибку, если запрос не удался
        } finally {
            setCancelOrderLoading(false); // Выключаем индикацию загрузки
        }
    };

    const [orderCoordinates, setOrderCoordinates] = useState(null);
    const [orderCoordinatesLoading, setOrderCoordinatesLoading] = useState(false);
    const [orderCoordinatesError, setOrderCoordinatesError] = useState(null);

    // Функция для получения заказов
    const fetchOrderCoordinates = async (shopId) => {
        setOrderCoordinatesLoading(true); // Включаем индикацию загрузки
        setOrderCoordinatesError(null); // Сбрасываем ошибки

        try {
            const data = await getOrderCoordinatesApi(shopId)
            setOrderCoordinates(data); // Устанавливаем данные заказов
        } catch (err) {
            setOrderCoordinatesError(err); // Устанавливаем ошибку, если запрос не удался
        } finally {
            setOrderCoordinatesLoading(false); // Выключаем индикацию загрузки
        }
    };

    useEffect(() => {
        fetchUserOrders(); // Автоматически вызываем запрос при монтировании компонента
    }, []);

    return {
        orders,
        loading,
        error,
        fetchUserOrders,

        cancelOrder,
        cancelOrderLoading,
        cancelOrderError,
        fetchCancelOrder,

        orderCoordinates,
        orderCoordinatesLoading,
        orderCoordinatesError,
        fetchOrderCoordinates
    };
};

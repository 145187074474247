import React, {useEffect, useState} from 'react';
import { useTelegram } from '../../../TelegramContext';
import {Link, useNavigate} from "react-router-dom";
import '../../../assets/css/style.css'; // Assuming you have a CSS file for styles
import { LinkProps, Link as RouterLink } from "react-router-dom";
import { BACKEND_URL } from '../../../utils/constants';

import Loader from "../../../components/Loader";
import useTranslation from "../../../hooks/useTranslation";
import Header from "../../../components/Header";
import {AutoIcon, BottomArrowIcon, LogoIcon, RightArrowIcon, UserIcon} from "../../../assets/svg/icons";
import {useUserOrders} from "../../../hooks/useUserOrders";
import { useSingleData} from '../../../context/SingleContext';
import {useGuarantees} from "../../../hooks/useGuarantees";
import topImage from "../../../assets/img/main/top2.png";
import FeedbackForm from "../../../components/FeedbackForm";
import ExtendedWarrantyActivateForm from "../include/ExtendedWarrantyActivateForm"; // Импортируем хук для работы с контекстом

import { useSearchParams } from 'react-router-dom';

function ExtendedWarrantyList() {

    const { user, triggerVibration, webApp  } = useTelegram();
    const navigate = useNavigate();
    const {translation} = useTranslation();

    const {guarantees, loadingGuarantees, errorGuarantees, fetchGuarantees} = useGuarantees();

    const [openedItem, setOpenedItem] = useState(null);

    const [noData, setNoData] = useState(true);
    const [isOpen, setIsOpen] = useState(false);


    const [searchParams] = useSearchParams();

    // Получаем значение параметра "showModal"
    const showModal = searchParams.get('showModal');


    useEffect(()=>{
        const isShowModalPresent = showModal === 'true';
        console.log(isShowModalPresent, 'isShowModalPresent')
        console.log(showModal, 'showModal')

        if (isShowModalPresent) {
            setIsOpen(true);
        }
    }, [])
    // Проверяем наличие параметра


    useEffect(()=>{
        if (guarantees) {
            if (guarantees?.data && guarantees?.data.length > 0) {
                // setNoData(false)
            } else {
                setNoData(true)
            }
            console.log(guarantees, 'guarantees')
        }
    }, [guarantees])

    function formatTimestampToReadableDate(timestamp) {
        const months = [
            'января', 'февраля', 'марта', 'апреля', 'мая', 'июня',
            'июля', 'августа', 'сентября', 'октября', 'ноября', 'декабря'
        ];

        const date = new Date(timestamp * 1000);
        const day = date.getDate();
        const month = months[date.getMonth()];
        const year = date.getFullYear();

        return `${day} ${month} ${year} г.`;
    }

    if (loadingGuarantees) {
        return (
            <Loader/>
        )
    }

    return (

        <div className="wrapper wrapper-flex" style={{padding: 0}}>

            <Header/>

            {/*{noData ?*/}
            {/*    <div className="top-block" style={{height: 70}}>*/}
            {/*        <Link to={'/'} className="top-block-logo">*/}
            {/*            <LogoIcon/>*/}
            {/*        </Link>*/}
            {/*    </div>*/}
            {/*    :*/}

                <div className="top-block" style={{backgroundImage: `url(${topImage})`, borderRadius: 0}}>
                    <Link to={'/'} className="top-block-logo">
                        <LogoIcon/>
                    </Link>
                </div>
            {/*}*/}


            <div
                className={'padding-block'}
                style={{
                    flex: 1,
                    borderRadius: "10px 10px 0 0 ",
                    position: 'relative',
                    top: -10,
                    background: "white",
                    overflow: "scroll"
                }}
            >
                <div className={'top-title-wrapper'}>

                    {noData ?
                        <div>
                            <div className={'top-title-text'} style={{marginLeft: 0}}>
                                Расширенные гарантии
                            </div>
                            <div className={'nodata-subtitle'}>
                                У вас нет действующих гарантий. Что такое расширенная гарантия?
                            </div>

                            <a href={'https://koleso.ru/garantii/'} target={'_blank'} className={'build-a-route'}>
                                Узнать о расширенной гарнтии
                            </a>
                        </div>

                        :
                        <div className={'top-title-text'} style={{marginLeft: 0}}>
                        Расширенные гарантии
                </div>
                    }

                </div>

                <div className={'list-wrapper'}>


                    {noData ?
                        <>

                        </>
                        :
                        <>
                            {guarantees?.data && guarantees?.data.map((item, index) => {
                                    return (
                                        <div
                                            key={index}
                                            onClick={() => {
                                                if (openedItem == index) {
                                                    setOpenedItem(null);
                                                } else {
                                                    setOpenedItem(index);
                                                }
                                            }}
                                            className={`list-item `}
                                            style={{padding: 0}}
                                        >

                                            <div className={'list-item-wrapper'}>
                                                <div className={'list-item-center'}>
                                                    <span>№ {item?.number}</span>
                                                </div>
                                                <div className={'list-item-center'}>
                                                    <span> {formatTimestampToReadableDate(item?.dataStart)} - {formatTimestampToReadableDate(item?.dataEnd)}</span>
                                                </div>

                                                <BottomArrowIcon className={'bottom-arrow'} style={{transform: 'rotate(0deg)'}}/>
                                            </div>

                                            <div className={`extended-toggle-wrapper ${openedItem == index ? 'is-open' : ''}`}>

                                                <p className={'extended-toggle-wrapper-title'}>Автомобиль:</p>
                                                <p className={'extended-toggle-wrapper-val'}>{item?.car || 'Не указан'}</p>

                                                <p className={'extended-toggle-wrapper-title'}>Комплект:</p>
                                                <p className={'extended-toggle-wrapper-val'}>{item?.brand}</p>

                                            </div>

                                        </div>
                                    )

                            })}

                        </>
                    }


                </div>


                {noData &&
                    <FeedbackForm title={'Насколько удобен личный кабинет?'} pageName={' Расширенные гарантии'}/>
                }
            </div>


            <div className={'bottom-btns-wrapper'}>
                <button
                    style={{ marginRight: 10}}
                    onClick={() => {
                        navigate(-1)
                    }}
                    className={'simple-btn'}
                >
                    Назад
                </button>
                <div
                    // to={'/extended-warranty-activate-form'}
                    onClick={()=>{
                        setIsOpen(true);
                    }}
                    style={{flex: 1, marginRight: 0, textDecoration: 'none'}}

                    className={'red-btn'}
                >
                    Активировать гарантию
                </div>

            </div>


            <ExtendedWarrantyActivateForm
                isOpen={isOpen}
                setOpen={setIsOpen}
            />

        </div>



    );
}

export default ExtendedWarrantyList;

import {Sheet} from "react-modal-sheet";
import React, {useEffect, useRef, useState} from "react";
import {useNavigate} from "react-router-dom";


const SettingsBottomSheet = ({
                                 isOpen, setOpen, modalType,
                                 setPhoneCallAllowed, setSmsMailingsAllowed, setEmailMailingsAllowed, setMessengerMailingsAllowed,
                                 setPhoneCallEnable, setSmsMailingsEnable, setEmailMailingsEnable, setMessengerMailingsEnable
}) => {
    const navigate = useNavigate();
    const ref = useRef();

    const [selectedReason, setSelectedReason] = useState(null)
    const [height, setHeight] = useState(310);

    const [title, setTitle] = useState('')

    const [reasonsList, setReasonsList] = useState([
        {id: 1, title: 'Соглашаюсь'},
        {id: 2, title: 'Отказываюсь'},
    ]);

    useEffect(()=> {

        setSelectedReason(null);

        if (modalType == 'phone') {
            setTitle('Согласны ли вы получать от нас телефонные звонки?')
        } else if (modalType == 'sms') {
            setTitle('Согласны ли вы получать от нас СМС-рассылки?')
        } else if (modalType == 'email') {
            setTitle('Согласны ли вы получать от нас email-рассылки?')
        } else if (modalType == 'messsenger') {
            setTitle('Согласны ли вы получать от нас мессенджер-рассылки?')
        }
        console.log(modalType, 'modalType')
    }, [modalType])

    const snapTo = (i) => ref.current?.snapTo(i);

    return (
        <Sheet
            isOpen={isOpen}
            onClose={() => setOpen(false)}
            initialSnap={0}
            snapPoints={[height, 200, 400, 0]}
        >
            <Sheet.Container>

                <Sheet.Header />
                <Sheet.Content>

                    <div className={'cancel-order-bottomshet-wrapper'}>
                       <div className={'cancel-order-bottomshet-container'}>

                            <h1 className={'cancel-order-bottomshet-title'}>
                                {title}
                            </h1>
                            <h2 className={'cancel-order-bottomshet-subtitle'}>Выберите</h2>


                            <div className={'reasons-list-wrapper'}>
                                {reasonsList?.map((item, index) => {
                                    return (
                                        <div
                                            key={index}
                                            onClick={() => {
                                                setSelectedReason(item?.id);
                                            }}
                                            className={'reasons-list-item'}
                                        >
                                            <div className={'custom-checkbox'}>
                                                {selectedReason == item?.id &&
                                                    <div className={'custom-checkbox-active'}>

                                                    </div>
                                                }
                                            </div>

                                            <span>{item?.title}</span>
                                        </div>
                                    )
                                })}
                            </div>

                            <div className={'bottom-btns-wrapper'} style={{padding: 0, paddingBottom: 0}}>
                                <button
                                    style={{opacity: selectedReason ? 1 : 0.5}}
                                    onClick={() => {
                                        if (selectedReason) {

                                            if (modalType == 'phone') {
                                                if (selectedReason == 1) {
                                                    // setPhoneCallAllowed(true)
                                                    setPhoneCallEnable(true)
                                                } else {
                                                    setPhoneCallEnable(false)
                                                }
                                            } else if (modalType == 'sms') {
                                                if (selectedReason == 1) {
                                                    setSmsMailingsEnable(true)
                                                } else {
                                                    setSmsMailingsEnable(false)
                                                }
                                            } else if (modalType == 'email') {
                                                if (selectedReason == 1) {
                                                    setEmailMailingsEnable(true);
                                                } else {
                                                    setEmailMailingsEnable(false);
                                                }
                                            } else if (modalType == 'messenger') {
                                                if (selectedReason == 1) {
                                                    setMessengerMailingsEnable(true)
                                                } else {
                                                    setMessengerMailingsEnable(false);
                                                }
                                            }


                                            // setEmailMailingsEnable
                                            // setMessengerMailingsEnable
                                            //

                                            setHeight(310);
                                            setOpen(false)
                                        }

                                    }}
                                    className={'red-btn'}
                                >
                                    Сохранить
                                </button>
                            </div>
                       </div>
                    </div>

                </Sheet.Content>
            </Sheet.Container>
            <Sheet.Backdrop/>
        </Sheet>
    )
}

export default SettingsBottomSheet

import React, {useEffect, useState} from "react";
import { GoogleReCaptchaProvider, useGoogleReCaptcha } from "react-google-recaptcha-v3";
import {
    Emoji1Icon,
    Emoji2Icon,
    Emoji3Icon,
    Emoji4Icon,
    Emoji5Icon,
    FeedBackErrorIcon,
    FeedBackSuccessIcon
} from "../../assets/svg/icons";
import useFeedBack from "../../hooks/useFeedBack"; // Подключаем хук

const FeedbackForm = ({ title, pageName }) => {
    const [message, setMessage] = useState(""); // Текстовое сообщение
    const [rating, setRating] = useState(0); // Рейтинг на основе выбора эмодзи
    const [showSuccessBlock, setsShowSuccessBlock] = useState(false); // Рейтинг на основе выбора эмодзи
    const [showErrorBlock, setsShowErrorBlock] = useState(false); // Рейтинг на основе выбора эмодзи

    // const [section, setSection] = useState("feedback"); // Статичный раздел, если он известен
    const { executeRecaptcha } = useGoogleReCaptcha();
    const { fetchCardFeedBack, feedbackAnswer, feedbackLoading, feedbackError } = useFeedBack();


    useEffect(() => {
        if (feedbackAnswer) {
            setMessage("");
            setRating(0);

            setsShowSuccessBlock(true);
            setTimeout(() => {
                setsShowSuccessBlock(false)
            }, 2000)
            console.log(feedbackAnswer, 'feedbackAnswer')
        }
    }, [feedbackAnswer])

    useEffect(() => {
        if (feedbackError) {
            console.log(feedbackError, 'feedbackError')
        }
    }, [feedbackError])


    const handleEmojiSelect = (index) => {
        setRating(index + 1); // Устанавливаем рейтинг (от 1 до 5)
    };

    const handleSubmit = async () => {
        if (!executeRecaptcha) {
            console.error("Recaptcha not yet available");
            return;
        }

        try {
            // Получаем токен reCAPTCHA
            const captchaTokenGoogle = await executeRecaptcha("submit");
            console.log(captchaTokenGoogle, 'captchaTokenGoogle')
            // Формируем данные для отправки
            const feedbackData = {
                urlPage: window.location.href, // Текущая страница
                message,
                rating,
                section: `МиниАпп: ${pageName}`,
                captchaTokenGoogle,
            };

            // Отправляем данные с помощью хука
            await fetchCardFeedBack(feedbackData);
        } catch (err) {
            console.error("Error during feedback submission:", err);
        }
    };

    if (showErrorBlock) {
        return (
            <div className={'feedback-success-wrapper'}>
                <span className={'feedback-success-wrapper-span1 error'}>Cервис временно не работает</span>

                    <FeedBackErrorIcon/>

                <span className={'feedback-success-wrapper-span2'}>Скоро всё исправим!</span>

                <button onClick={()=> {setsShowErrorBlock(false)}}>Понятно</button>

            </div>
        )
    } else if (showSuccessBlock) {
        return (
            <div className={'feedback-success-wrapper'}>
                <span className={'feedback-success-wrapper-span1'}>Сообщение отправлено</span>

                <FeedBackSuccessIcon/>

                <span className={'feedback-success-wrapper-span2'}>Спасибо за обратную связь!</span>

                <button onClick={()=> {setsShowSuccessBlock(false)}}>Хорошо</button>
            </div>
        )
    } else {
        return (
            <div className={"feedback-wrapper"}>
                <h1>{title}</h1>

                <div className={"feedback-wrapper-emoji"}>
                    {[Emoji1Icon, Emoji2Icon, Emoji3Icon, Emoji4Icon, Emoji5Icon].map((EmojiIcon, index) => (
                        <div
                            key={index}
                            className={`feedback-wrapper-emoji-item ${rating === index + 1 ? "selected" : ""}`}
                            onClick={() => handleEmojiSelect(index)}
                        >
                            <EmojiIcon />
                        </div>
                    ))}
                </div>

                <textarea
                    className={'feedback-textarea'}
                    placeholder="Ваш комментарий..."
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                ></textarea>

                <button onClick={handleSubmit} disabled={feedbackLoading}>
                    {feedbackLoading ? "Отправка..." : "Ответить"}
                </button>

                {/*{feedbackAnswer && <p>Спасибо за ваш отзыв!</p>}*/}
                {/*{feedbackError && <p>Произошла ошибка: {feedbackError.message}</p>}*/}
            </div>
        );
    }

};

// Оборачиваем компонент в GoogleReCaptchaProvider в корневом компоненте
const App = ({pageName, title}) => {
    return (
        <FeedbackForm title="Оставьте ваш отзыв" pageName={pageName}/>
    );
};

export default App;

import React, {useEffect, useState} from 'react';
import { useTelegram } from '../../../TelegramContext';
import {Link, useNavigate} from "react-router-dom";
import '../../../assets/css/style.css'; // Assuming you have a CSS file for styles

import Loader from "../../../components/Loader";
import useTranslation from "../../../hooks/useTranslation";
import Header from "../../../components/Header";
import {AutoIcon, BottomArrowIcon, LogoIcon, PlusIcon, RightArrowIcon, UserIcon} from "../../../assets/svg/icons";
import {useUserOrders} from "../../../hooks/useUserOrders";
import { useSingleData} from '../../../context/SingleContext';
import orderImage from "../../../assets/img/main/orders.png";
import FeedbackForm from "../../../components/FeedbackForm"; // Импортируем хук для работы с контекстом

function MyOrdersList() {

    const { user, triggerVibration, webApp  } = useTelegram();
    const navigate = useNavigate();
    const {translation} = useTranslation();
    const {orders, loading, error, fetchUserOrders} = useUserOrders();
    const { selectedSingleData, setSelectedSingleData } = useSingleData(); // Достаем функцию для установки выбранного заказа
    const [noData, setNoData] = useState(false);

    useEffect(()=>{
        if (orders) {
            console.log(orders, 'orders')
            if (orders?.data?.filter(order => order.orderType == 0).length == 0) {
                setNoData(true);
            } else  {
                setNoData(false);
            }
        }
    }, [orders])

    if (loading) {
        return (
            <Loader/>
        )
    }

    return (

        <div className="wrapper wrapper-flex" style={{padding: 0}}>

            <Header/>

            {/*{noData ?*/}
            {/*    <div className="top-block" style={{height: 70}}>*/}
            {/*        <Link to={'/'} className="top-block-logo">*/}
            {/*            <LogoIcon/>*/}
            {/*        </Link>*/}
            {/*    </div>*/}
            {/*    :*/}

            {/*    <div className="top-block" style={{backgroundImage: `url(${orderImage})`, borderRadius: 0}}>*/}
            {/*        <Link to={'/'} className="top-block-logo">*/}
            {/*            <LogoIcon/>*/}
            {/*        </Link>*/}
            {/*    </div>*/}
            {/*}*/}


            <div className="top-block" style={{backgroundImage: `url(${orderImage})`, borderRadius: 0}}>
                <Link to={'/'} className="top-block-logo">
                    <LogoIcon/>
                </Link>
            </div>

            <div
                className={'padding-block'}
                style={{
                    flex: 1,
                    borderRadius: "10px 10px 0 0 ",
                    position: 'relative',
                    top: -10,
                    background: "white",
                    overflow: "scroll"
                }}
            >
                <div className={'top-title-wrapper'}>

                    {noData ?
                        <div className={'top-title-text'} style={{marginLeft: 0}}>
                            Пока нет заказов, но это поправимо...
                        </div>
                        :
                        <div className={'top-title-text'} style={{marginLeft: 0}}>
                            Мои заказы
                        </div>
                    }

                </div>

                <div className={'list-wrapper'}>


                    {noData ?
                        <>
                            <img style={{width: '100%'}} src={require('../../../assets/img/myorders/noorders.png')} alt=""/>
                        </>
                        :
                        <>
                            {orders?.data && orders?.data.map((item, index)=>{
                                // if (item?.orderType == 1) {
                                if (item?.orderType == 0) { //Должно быть 0
                                    return(
                                        <Link
                                            key={index}
                                            to={'/my-orders-single'}
                                            onClick={() => {
                                                setSelectedSingleData(item)
                                            }}
                                            className={`list-item `}
                                            style={{padding: 0}}
                                        >

                                            <div className={'list-item-wrapper'}>
                                                <div className={'list-item-center'}>
                                                    <span>№ {item?.orderNumber}</span>
                                                    {item?.cancellationProcess == 1 &&
                                                        <p className={'cancelation-yellow-block'}>отменяем</p>
                                                    }
                                                </div>
                                                <div className={'list-item-center'}>
                                                    <span>от {item?.orderDate}</span>
                                                </div>

                                                <div className={'list-item-bottom'}
                                                     style={{display: 'flex', justifyContent: 'space-between'}}>
                                                    <span>на сумму:</span>
                                                    <span>{item?.totalSumOrder} ₽</span>
                                                </div>

                                                <BottomArrowIcon className={'bottom-arrow'} style={{transform: 'rotate(-90deg)'}}/>
                                            </div>

                                        </Link>
                                    )
                                }

                            })}

                        </>
                    }


                </div>


                {noData &&
                    <FeedbackForm title={'Насколько удобен личный кабинет?'} pageName={'Мои заказы'}/>
                }
            </div>


            <div className={'bottom-btns-wrapper'}>
                <button
                    style={{flex:1, marginRight:0}}
                    onClick={() => {
                        navigate(-1)
                    }}
                    className={'simple-btn'}
                >
                    Назад
                </button>

            </div>


        </div>

    );
}

export default MyOrdersList;

import React, { useEffect, useRef } from 'react';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { useTelegram } from '../src/TelegramContext/index';
import { validateSMS } from './utils/api/authApi'; // Импортируем функцию валидации SMS

import Main from './pages/Main';
import MyCars from './pages/MyCars';
import MyOrders from './pages/MyOrders/List';
import MyOrdersSingle from './pages/MyOrders/Single';
import RegistrationsForServices from './pages/RegistrationsForServices/List';
import RegistrationsForServiceSingle from './pages/RegistrationsForServices/Single';
import WheelStorageList from './pages/WheelStorage/List';
import WheelStorageSingle from './pages/WheelStorage/Single';
import MyPurchasesList from './pages/MyPurchases/List';
import MyPurchasesSingle from './pages/MyPurchases/Single';
import MyDiscounts from './pages/MyDiscounts';
import Profile from "./pages/Profile";

import ExtendedWarrantyList from "./pages/ExtendedWarranty/List";
import ExtendedWarrantyActivateForm from "./pages/ExtendedWarranty/ActivateForm";

import Loader from "./components/Loader";
import useTranslation from "./hooks/useTranslation";

import { SingleProvider } from './context/SingleContext';
import Settings from "./pages/Settings";
import SliderComponent from "./components/SliderComponent";
import ErrorPage from "./pages/ErrorPage";
import ErrorModal from "./components/ErrorModal"; // Импортируем контекст

function App() {
    const { user, webApp } = useTelegram(); // Используем TelegramContext

    const [loader, setLoader] = React.useState(true);
    const [showErrorModal, setShowErrorModal] = React.useState(false);
    const [splashScreen, setSplashScreen] = React.useState(true);


    const navigate = useNavigate();
    const location = useLocation();



    useEffect(() => {
        checkUser();
    }, []);

    const checkUser = async () => {

        let queryParams = new URLSearchParams(window.location.search);
        let phone = queryParams.get('phone');
        let tgId = queryParams.get('tgId');

        console.log(phone,'phone')
        console.log(tgId,'tgId')
        console.log(user,'webApp')
        console.log(user,'webApp')

        if (!phone || !tgId) {
            phone = localStorage.getItem('phone');
            tgId = localStorage.getItem('tgId');
            if (!phone || !tgId) {
                setLoader(false);
                // navigate('/error-page')
                setShowErrorModal(true)
                return false;
            }
        }

        try {
            // http://localhost:3000/?phone=+79250111405&tgId=844536969
            // http://localhost:3000/?phone=+79259445384&tgId=844536969

            // http://localhost:3000?phone=+79250111405&tgId=844536969
            // http://localhost:3000?phone=+79259445384&tgId=844536969
            // http://localhost:3000?phone=+79055886020&tgId=844536969

            // http://localhost:3000?phone=+79258888996&tgId=844536969
            // http://localhost:3000?phone=+79265891540&tgId=844536969
            // http://localhost:3000?phone=+79260402946&tgId=844536969
            // http://localhost:3000?phone=+79850420000&tgId=844536969

            // http://localhost:3000?phone=+79263767122&tgId=844536969
            // http://localhost:3000?phone=+79188933322&tgId=844536969
            // https://koleso-miniapp.dev.sebekon.ru/?phone=+79055886020&tgId=844536969


            // http://localhost:3000?phone=+79055886020&tgId=844536969


            const code = "9456";
            const telegramId = tgId || null//844536969; // Получаем telegramId из TelegramContext

            console.log(phone, code, telegramId)
            const { data } = await validateSMS(phone, code, telegramId);

            console.log(data?.token, 'data.token')
            console.log(data?.refresh_token, 'data.refresh_token')
            // return false

            // Сохраняем токен и refresh токен в localStorage
            localStorage.setItem('token', data.token);
            localStorage.setItem('refresh_token', data.refresh_token);
            localStorage.setItem('phone', phone);
            localStorage.setItem('tgId', telegramId);
            // Обновляем язык приложения на основе данных пользователя
            // handleLanguageChange(data?.language || 'en');

            // Отключаем лоадер через 1 секунду
            setTimeout(() => {
                setLoader(false);
            }, 2000);

        } catch (error) {
            console.error('Failed to validate SMS:', error);
            setLoader(false);

            // navigate('/error-page')
            setShowErrorModal(true)

            // В случае ошибки отключаем лоадер
        }
    };


    useEffect(() => {
        // Инициализация WebApp
        const webApp = window.Telegram.WebApp;
        webApp.ready();

        // Получаем initData из WebApp
        const initData = webApp.initData;
        alert(`https://koleso-chatbot.dev.sebekon.ru/query.php?initData=${initData}`);


        // Отправляем на сервер для проверки
        fetch(`https://koleso-chatbot.dev.sebekon.ru/query.php?initData=${initData}`, {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
        })
            .then((response) => {
                if (response.status === 200) {
                    return response.text();
                }
                throw new Error('Validation failed');
            })
            .then((data) => {

                alert(initData);
            })
            .catch((error) => {
                // alert('ERROR')
            });
    }, [ window.Telegram, window.Telegram.WebApp]);



    if (showErrorModal) {
        return <ErrorModal/>
    }
    // Если лоадер активен, показываем его
    if (splashScreen) {
        return <SliderComponent onClose={()=>{setSplashScreen(false)}} />;
    }

    // Если лоадер активен, показываем его
    if (loader) {
        return <Loader />;
    }

    return (
        <SingleProvider>

            <Routes>
                <Route path="/" element={<Main />} />
                <Route path="/my-cars" element={<MyCars />} />

                <Route path="/my-orders" element={<MyOrders />} />
                <Route path="/my-orders-single" element={<MyOrdersSingle />} />

                <Route path="/registrations-for-services" element={<RegistrationsForServices />} />
                <Route path="/registrations-for-service-single" element={<RegistrationsForServiceSingle />} />

                <Route path="/wheel-storage-list" element={<WheelStorageList />} />
                <Route path="/wheel-storage-single" element={<WheelStorageSingle />} />

                <Route path="/my-purchases-list" element={<MyPurchasesList />} />
                <Route path="/my-purchases-single" element={<MyPurchasesSingle />} />

                <Route path="/my-discount" element={<MyDiscounts />} />
                <Route path="/my-profile" element={<Profile />} />

                <Route path="/extended-warranty-list" element={<ExtendedWarrantyList />} />
                <Route path="/extended-warranty-activate-form" element={<ExtendedWarrantyActivateForm />} />

                <Route path="/settings" element={<Settings />} />
                <Route path="/error-page" element={<ErrorPage />} />
            </Routes>
        </SingleProvider>

    );
}

export default App;

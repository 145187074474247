import {Sheet} from "react-modal-sheet";
import React, {useEffect, useRef, useState} from "react";
import {useNavigate} from "react-router-dom";
import {useUserOrders} from "../../../../../hooks/useUserOrders";


const CancelOrderBottomSheet = ({isOpen, setOpen, selectedSingleData}) => {
    const navigate = useNavigate();
    const ref = useRef();
    const {
        cancelOrder,
        cancelOrderLoading,
        cancelOrderError,
        fetchCancelOrder
    } = useUserOrders();


    const [selectedReason, setSelectedReason] = useState(null)
    const [selectedFromReason, setSelectedFromReason] = useState(null)
    const [height, setHeight] = useState(window.innerHeight-100);
    const [textareaValue, setTextareaValue] = useState(''); // Состояние для хранения значения textarea

    const [cancelSuccess, setCancelSuccess] = useState(false)

    const [reasonsList, setReasonsList] = useState([
        {id: 1, title: 'Не устроили сроки', fromReason: 'DEADLINE'},
        {id: 2, title: 'Уже купил(а) у вас', fromReason: "ALREADY"},
        {id: 3, title: 'Купил(а) в другой компании', fromReason: "ANOTHER"},
        {id: 4, title: 'Ошибка с выбором', fromReason: "SELECTION_ERROR"},
        {id: 5, title: 'Проблемы с кредитом/рассрочкой', fromReason: "PROBLEM_CREDIT"},
        {id: 6, title: 'Не устроила цена', fromReason: "VERY_EXPENSIVE"},
        {id: 7, title: 'Передумал покупать', fromReason: "CHANGED_MY_MIND"},
    ]);


    const handleTextareaChange = (e) => {
        setTextareaValue(e.target.value); // Обновляем состояние при изменении текста
    };


    useEffect(()=>{
        if (cancelOrder) {
            console.log(cancelOrder, 'cancelOrder')
            setCancelSuccess(true);
            setHeight(200)
        }
    }, [cancelOrder])

    useEffect(()=>{
        if (cancelOrderError) {
            console.log(cancelOrderError, 'cancelOrderError')
        }
    }, [cancelOrderError])

    return (
        <Sheet
            isOpen={isOpen}
            onClose={() => setOpen(false)}
            initialSnap={0}
            snapPoints={[height, 200, 400, 0]}
        >
            <Sheet.Container>

                <Sheet.Header />
                <Sheet.Content>

                    <div className={'cancel-order-bottomshet-wrapper'}>
                        {!cancelSuccess ?

                            <>
                                <div className={'cancel-order-bottomshet-container'}>

                                    <h1 className={'cancel-order-bottomshet-title'}>Отмена заказа</h1>
                                    <h2 className={'cancel-order-bottomshet-subtitle'}>Выберите причину</h2>


                                    <div className={'reasons-list-wrapper'}>
                                        {reasonsList?.map((item, index) => {
                                            return (
                                                <div
                                                    key={index}
                                                    onClick={() => {
                                                        setSelectedReason(item?.id);
                                                        setSelectedFromReason(item?.fromReason)
                                                    }}
                                                    className={'reasons-list-item'}
                                                >
                                                    <div className={'custom-checkbox'}>
                                                        {selectedReason == item?.id &&
                                                            <div className={'custom-checkbox-active'}>

                                                            </div>
                                                        }
                                                    </div>

                                                    <span>{item?.title}</span>
                                                </div>
                                            )
                                        })}
                                    </div>

                                    {selectedReason &&
                                        <textarea
                                            className={'cancel-order-textarea'}
                                            placeholder={'Расскажите подробнее'} name="" id="" cols="30"
                                            rows="10" value={textareaValue} // Связываем значение textarea с состоянием
                                            onChange={handleTextareaChange} // Обработчик изменения текста
                                        />
                                    }

                                </div>
                                <div className={'bottom-btns-wrapper'} style={{padding: 0, paddingBottom: 0}}>
                                <button
                                        style={{flex: 1}}
                                        onClick={() => {

                                            setCancelSuccess(false);
                                            setOpen(false)
                                            setTimeout(()=>{
                                                setHeight(window.innerHeight-100)
                                                setSelectedReason(null)
                                            }, 1000)

                                        }}
                                        className={'simple-btn'}
                                    >
                                        Закрыть
                                    </button>
                                    <button
                                        style={{opacity: selectedReason && !cancelOrderLoading ? 1 : 0.5}}
                                        onClick={() => {

                                            if (!cancelOrderLoading) {
                                                fetchCancelOrder(selectedSingleData?.orderRn, selectedFromReason, textareaValue)
                                                console.log(selectedSingleData?.orderRn, 'orderNumber')
                                                console.log(selectedFromReason, 'selectedFromReason')
                                                console.log(textareaValue, 'textareaValue')
                                            }

                                        }}

                                        className={'red-btn'}
                                    >
                                        Отменить заказ
                                    </button>
                                </div>
                            </>

                            :

                            <div className={'cancel-success-wrapper'}>
                                <div className={'cancel-success-message'}>
                                    Спасибо! Мы передали информацию менеджеру
                                </div>
                                <div className={'bottom-btns-wrapper'} style={{padding: 0, paddingBottom: 0}}>
                                    <button
                                        style={{flex: 1}}
                                        onClick={() => {
                                            setCancelSuccess(false);
                                            setOpen(false)
                                            setTimeout(()=>{
                                                setHeight(window.innerHeight-100)
                                                setSelectedReason(null)
                                            }, 1000)

                                            navigate(-1)
                                        }}
                                        className={'simple-btn'}
                                    >
                                        Закрыть
                                    </button>
                                </div>
                            </div>

                        }

                    </div>

                </Sheet.Content>
            </Sheet.Container>
            <Sheet.Backdrop/>
</Sheet>
)
}

export default CancelOrderBottomSheet

import React, {useEffect, useRef, useState} from 'react';
import { useTelegram } from '../../../TelegramContext';
import {Link, useNavigate} from "react-router-dom";
import '../../../assets/css/style.css'; // Assuming you have a CSS file for styles
import { LinkProps, Link as RouterLink } from "react-router-dom";

import Loader from "../../../components/Loader";
import useTranslation from "../../../hooks/useTranslation";
import Header from "../../../components/Header";
import {useSingleData} from "../../../context/SingleContext";
import {LogoIcon} from "../../../assets/svg/icons";
import { Sheet } from 'react-modal-sheet';
import CancelOrderBottomSheet from "./include/CancelOrderBottomSheet";

function MyOrdersSingle() {

    // const windowSize = useRef([
    //     window.innerWidth,
    //     window.innerHeight,
    // ]);

    const { user, triggerVibration, webApp  } = useTelegram();
    const navigate = useNavigate();
    const {translation} = useTranslation();
    const { selectedSingleData, setSelectedSingleData } = useSingleData(); // Получаем выбранный заказ из контекста

    const [loader, setLoader] = useState(true);
    const [isOpen, setOpen] = useState(false);

    useEffect(()=>{
        if (selectedSingleData) {
            setLoader(false);
        }
        console.log(selectedSingleData, 'selectedOrder')
    }, [selectedSingleData])

    if (loader) {
        return (
            <Loader/>
        )
    }

    return (
        <>
            <div className="wrapper wrapper-flex">

                <Header/>

                <Link to={'/'} className="top-block-logo">
                    <LogoIcon/>
                </Link>

                <div className={'list-wrapper'} style={{flex: 1, marginTop: 70, overflow: 'scroll'}}>

                    <div className={'list-item'} style={{background: 'unset', padding: 0}}>
                        <div className={'list-item-center'} style={{flexDirection: "column", alignItems: 'flex-start'}}>
                            <div style={{display: "flex"}}>
                                <span style={{
                                    fontSize: 20,
                                    fontWeight: 700
                                }}>Заказ № {selectedSingleData?.orderNumber}</span>
                                {selectedSingleData?.cancellationProcess == 1 &&
                                    <p className={'cancelation-yellow-block'}>отменяем</p>
                                }
                            </div>

                            <span style={{paddingLeft: 0}}>от {selectedSingleData?.orderDate} г.</span>
                        </div>

                    </div>

                    {selectedSingleData?.products && selectedSingleData?.products.map((item, index) => {
                        if (item?.code) {
                            return (
                                <Link to={`https://koleso.ru${item?.code}`} key={index} className={'order-item'} style={{textDecoration:'none'}}>
                                    <div className={'order-item-left'}>
                                        <img src={item?.image}/>
                                    </div>
                                    <div className={'order-item-right'} style={{color:'black'}}>
                                        <p className={'order-item-title'}>
                                            {item?.title}
                                        </p>
                                        <p className={'order-item-price'}>{item?.price / item?.quantity} ₽/шт.</p>
                                        <div className={'order-item-bottom'}>
                                            <span>{item?.quantity} шт.</span>
                                            <span style={{fontSize: 16, fontWeight: '700'}}>{item?.price} ₽</span>
                                        </div>
                                    </div>

                                </Link>
                            )
                        } else {
                            return (
                                <div  key={index} className={'order-item'} style={{textDecoration:'none'}}>
                                    <div className={'order-item-left'}>
                                        <img src={item?.image}/>
                                    </div>
                                    <div className={'order-item-right'} style={{color:'black'}}>
                                        <p className={'order-item-title'}>
                                            {item?.title}
                                        </p>
                                        <p className={'order-item-price'}>{item?.price / item?.quantity} ₽/шт.</p>
                                        <div className={'order-item-bottom'}>
                                            <span>{item?.quantity} шт.</span>
                                            <span style={{fontSize: 16, fontWeight: '700'}}>{item?.price} ₽</span>
                                        </div>
                                    </div>

                                </div>
                            )
                        }

                    })}


                </div>


                <div className={'bottom-btns-wrapper'} style={{padding: 0, paddingBottom: 20}}>
                    <button
                        style={{flex: 1}}
                        onClick={() => {
                            navigate(-1);
                        }}
                        className={'simple-btn'}
                    >
                        Назад
                    </button>
                    <button
                        style={selectedSingleData?.cancellationProcess == 1 ? {opacity: 0.5} : {}}
                        onClick={() => {
                            if (selectedSingleData?.cancellationProcess == 0) {
                                setOpen(true)
                            }
                        }}
                        className={'red-btn'}
                    >
                        Отменить заказ
                    </button>
                </div>


            </div>

            <CancelOrderBottomSheet
                isOpen={isOpen}
                setOpen={setOpen}
                selectedSingleData={selectedSingleData}
            />
        </>
    );
}

export default MyOrdersSingle;

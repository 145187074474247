import axios from 'axios';

// Загружаем переменные окружения
const authorizationToken = process.env.REACT_APP_AUTHORIZATION_TOKEN;
const apiUrl = process.env.REACT_APP_API_URL;

// Создание экземпляра axios с базовой конфигурацией
const apiClient = axios.create({
    baseURL: apiUrl, // базовый URL для API
    headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${authorizationToken}`, // Получение токена из переменных окружения
    }
});

// Интерцептор для добавления токена из localStorage в заголовки каждого запроса
apiClient.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem('token'); // Получаем токен из localStorage
        if (token) {
            config.headers['token'] = token; // Добавляем токен в заголовок
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

// Функция для обновления токена
const refreshAuthToken = async () => {
    const refreshToken = localStorage.getItem('refresh_token'); // Получаем refresh token из localStorage

    console.log(refreshToken, 'refreshToken')
    if (!refreshToken) {
        console.error('No refresh token found');
        return null;
    }

    try {
        const response = await axios.post(`${apiUrl}/auth/refresh`, {}, {
            headers: {
                'token': refreshToken
            }
        });

        console.log(response.data.data, 'response.data.data')
        const { token, refresh_token: newRefreshToken } = response.data.data;

        if (newRefreshToken) {
            // Сохраняем новый токен и refreshToken в localStorage
            localStorage.setItem('token', token);
            localStorage.setItem('refresh_token', newRefreshToken);

            return token;
        } else {
            return '';
        }



    } catch (error) {
        console.error('Error refreshing token:', error);
        return null;
    }
};

// Интерцептор для обработки ошибок
apiClient.interceptors.response.use(
    (response) => response,
    async (error) => {
        const originalRequest = error.config;

        // Если запрос вернул ошибку 401 (Unauthorized) и это не запрос на обновление токена
        if (error.response && error.response.status === 401 && !originalRequest._retry) {
            originalRequest._retry = true;

            // Попробуем обновить токен
            const newToken = await refreshAuthToken();

            if (newToken) {
                // Обновляем заголовок с новым токеном
                originalRequest.headers['token'] = newToken;

                // Повторяем оригинальный запрос с новым токеном
                return apiClient(originalRequest);
            }
        }

        return Promise.reject(error);
    }
);

export default apiClient;

import { useState, useEffect } from 'react';
import {getDiscount, getUserCard} from '../utils/api/cardApi';
import {feedbackApi} from "../utils/api/feedbackApi"; // Импорт функции получения профиля

const useFeedBack = () => {
    const [feedbackAnswer, setFeedbackAnswer] = useState(null); // Состояние для хранения профиля
    const [feedbackLoading, setFeedbackLoading] = useState(false); // Состояние для индикации загрузки
    const [feedbackError, setFeedbackError] = useState(null); // Состояние для хранения ошибок

    // Функция для получения профиля
    const fetchCardFeedBack = async (data) => {

        setFeedbackLoading(true);
        setFeedbackError(null);
        setFeedbackAnswer(null);

        const phone = localStorage.getItem('phone');

        try {
            const response = await feedbackApi(data);
            setFeedbackAnswer(response);
        } catch (err) {
            setFeedbackError(err);
        } finally {
            setFeedbackLoading(false);
        }
    };



    return {
        feedbackAnswer,
        feedbackLoading,
        feedbackError,
        fetchCardFeedBack ,
    };
};

export default useFeedBack;
